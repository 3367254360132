<template>
    <div class="login" v-if="$store.state.settingsInfo.loginPage === 1">
        <div class="logo">
                <!-- logo -->
                <!-- <img src="../assets/home/logo.png" alt=""/> -->
                <img :src="$store.state.settingsInfo.loginLogo" alt=""/>
            </div>
        <div class="content">
            <div class="container">
                <div class="topName">
                    <span>心理测评系统</span>
                </div>
                <div class="tableContent">
                    <p>找回密码</p>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                        <el-form-item prop="account">
                            <el-input v-model="ruleForm.account" prefix-icon="el-icon-user" placeholder="登录名/手机号">
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')" :loading="loading">{{ loading ?
                                    '验证中 ...' : '验证'
                            }}</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
    <div class="login2" v-else>
        <div class="content">
            <div class="logo">
                <!-- logo -->
                <!-- {{$store.state.settingsInfo.loginPage}} -->
                <img :src="$store.state.settingsInfo.loginLogo" alt=""/>
            </div>
            <div class="container">
                <div class="tableContent">
                    <p>找回密码</p>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                        <el-form-item prop="account">
                            <el-input v-model="ruleForm.account" prefix-icon="el-icon-user" placeholder="登录名/手机号">
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')" :loading="loading">{{ loading ?
                                    '验证中 ...' : '验证'
                            }}</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { checkUser } from '@/api/forgetPwd'
import {mapActions} from 'vuex'
export default {
    data() {
        return {
            settingInfo: {
                loginPage: 2,
            },
            ruleForm: {
                account: ''
            },
            rules: {
                account: [
                    { required: true, message: '请输入登录名/手机号', trigger: 'blur' }
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ]
            },
            loading: false
        };
    },
    methods: {
        ...mapActions(['getInfo']),
        // 点击验证
        submitForm(formName) {
            this.loading = true;
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    //  该账户不存在，请检查并重新输入
                    // 该账户无法自主找回密码，请联系管理员重置密码
                    //  如果验证通过则进入找回密码界面
                    const res = await checkUser({ username: this.ruleForm.account });
                    console.log(res);
                    // let info = {}
                    if (res.code === 200) {
                        localStorage.setItem('getPwd', JSON.stringify(res.data))
                        setTimeout(() => {
                            this.loading = false;
                            this.$router.push('/forgetPwd');
                        }, 400);
                    } else {
                        setTimeout(() => {
                            this.$message({
                                showClose: true,
                                message: res.msg,
                                type: 'error'
                            });
                            this.loading = false;
                            // this.$router.push('/forgetPwd');
                        }, 400);
                    }
                    // this.$router.push('/forgetPwd');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
    created(){
        this.getInfo();
    }
}
</script>

<style lang="scss" scoped>
.login {
    // background-image: url('../assets/home/backgroundImage.png');
    background-color: var(--theme-color);
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    // background-color: antiquewhite;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .topName{
// 		position: absolute;
// 		// text-align: center;
// 		// width: 440px;
// 		// top:60px;
		font-size: 32px;
		font-weight: 600;
		color: var(--custom-color);
// 		display: flex;
// 		justify-content: center;
	}
    .content {
        position: relative;

        .el-button {
            width: 100%;
            // font-size: 26px;
            border-radius: 4px;
            box-sizing: border-box;
            margin-top: 20px;
        }


        // .logo {
        //     z-index: 2;
        //     text-align: center;
        //     line-height: 160px;
        //     position: absolute;
        //     top: -80px;
        //     left: calc(550px - 50% - 80px);
        //     ;
        //     width: 160px;
        //     height: 160px;
        //     background: #FFFFFF;
        //     border-radius: 50%;
        //     box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3600);

        // }

        .container {
            // width: 650px;
            // // height: 614px;
            // background-color: rgba(255, 255, 255, 0.5);
            // border-radius: 12px;
            // // opacity: 0.5;
            // filter: blur(0px);
            // padding: 50px 55px;
            // padding-top: 120px;
            // box-sizing: border-box;

            p {
                margin: 0;
                padding: 0;
                font-size: 18px;
                font-weight: 600;
                color: var(--custom-color);
                margin-bottom: 20px;
            }

        }
    }
}
.login2 {
    // background-image: url('../assets/home/backgroundImage.png');
    background-color: var(--theme-color);
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    // background-color: antiquewhite;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo{
	img{
		width: 60%;
		// height: 80%;
	}
	display: flex;
	justify-content: center;
	align-items: center;
}
    .content {
        position: relative;

        .el-button {
            width: 100%;
            // font-size: 26px;
            border-radius: 4px;
            box-sizing: border-box;
            margin-top: 20px;
        }


        // .logo {
        //     z-index: 2;
        //     text-align: center;
        //     line-height: 160px;
        //     position: absolute;
        //     top: -80px;
        //     left: calc(550px - 50% - 80px);
        //     ;
        //     width: 160px;
        //     height: 160px;
        //     background: #FFFFFF;
        //     border-radius: 50%;
        //     box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3600);

        // }

        .container {
            // width: 650px;
            // // height: 614px;
            // background-color: rgba(255, 255, 255, 0.5);
            // border-radius: 12px;
            // // opacity: 0.5;
            // filter: blur(0px);
            // padding: 50px 55px;
            // padding-top: 120px;
            // box-sizing: border-box;

            p {
                margin: 0;
                padding: 0;
                font-size: 18px;
                font-weight: 600;
                color: var(--custom-color);
                margin-bottom: 20px;
            }

        }
    }
}


@media screen and (min-width: 751PX) {
    
    .login {
        // background-image: url('../assets/home/backgroundImage.png');
        // background-color: var(--theme-color);
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;
        background-size: cover;
        // background-color: antiquewhite;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo{
		position: fixed;
		top: 50px;
		left: 60px;
		img{
			height: 60px;
		}
	}
        .topName{
		position: absolute;
		// text-align: center;
		width: 440px;
		top:60px;
		font-size: 32px;
		font-weight: 600;
		color: var(--custom-color);
		display: flex;
		justify-content: center;
	}

        .content {
            position: relative;

            .el-button {
                width: 100%;
                // font-size: 26px;
                border-radius: 4px;
                box-sizing: border-box;
                margin-top: 20px;
            }


            // .logo {
            //     z-index: 2;
            //     text-align: center;
            //     line-height: 160px;
            //     position: absolute;
            //     top: -80px;
            //     left: calc(550px - 50% - 80px);
            //     ;
            //     width: 160px;
            //     height: 160px;
            //     background: #FFFFFF;
            //     border-radius: 50%;
            //     box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3600);

            // }

            .container {
                width: 550px;
                // height: 614px;
                background-color: rgba(255, 255, 255, 0.5);
                border-radius: 12px;
                // opacity: 0.5;
                filter: blur(0px);
                padding: 50px 55px;
                padding-top: 120px;
                box-sizing: border-box;

                p {
                    margin: 0;
                    padding: 0;
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--custom-color);
                    margin-bottom: 20px;
                }

            }
        }
    }
    .login2 {
        // background-image: url('../assets/home/backgroundImage.png');
        // background-color: var(--theme-color);
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;
        background-size: cover;
        // background-color: antiquewhite;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .content {
            position: relative;

            .el-button {
                width: 100%;
                // font-size: 26px;
                border-radius: 4px;
                box-sizing: border-box;
                margin-top: 20px;
            }


            .logo {
                z-index: 2;
                text-align: center;
                line-height: 160px;
                position: absolute;
                top: -80px;
                left: calc(550px - 50% - 80px);
                ;
                width: 160px;
                height: 160px;
                background: #FFFFFF;
                border-radius: 50%;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3600);

            }

            .container {
                width: 550px;
                // height: 614px;
                background-color: rgba(255, 255, 255, 0.5);
                border-radius: 12px;
                // opacity: 0.5;
                filter: blur(0px);
                padding: 50px 55px;
                padding-top: 120px;
                box-sizing: border-box;

                p {
                    margin: 0;
                    padding: 0;
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--custom-color);
                    margin-bottom: 20px;
                }

            }
        }
    }
}

@media screen and (max-width: 750PX) {
    

    .login {
        // background-image: url('../assets/home/backgroundImage.png');
        background-color: var(--theme-color);
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;
        background-size: cover;
        // background-color: antiquewhite;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        .topName{
		margin-bottom: 40px;
		font-size: 20px;
		text-align: center;
	}
    .logo{
		// position: fixed;
		padding-top: 20px;
		padding-left: 20px;
		margin-bottom: 40px;
		img{
			height: 30px;
		}
	}
    .content {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
       
    }

    .container {
        width: 96%;
    }
        .content {
            position: relative;

            .el-button {
                width: 100%;
                // font-size: 26px;
                border-radius: 4px;
                box-sizing: border-box;
                margin-top: 20px;
            }


            // .logo {
            //     z-index: 2;
            //     text-align: center;
            //     line-height: 120px;
            //     position: absolute;
            //     top: -120px;
            //     // left: calc(550px - 50% - 80px);
            //     // ;
            //     width: 120px;
            //     height: 120px;
            //     // background: #FFFFFF;
            //     // border-radius: 50%;
            //     // box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3600);

            // }

            .container {
                // width: 550px;
                // height: 614px;
                // background-color: rgba(255, 255, 255, 0.5);
                // border-radius: 12px;
                // // opacity: 0.5;
                // filter: blur(0px);
                // padding: 50px 55px;
                // padding-top: 120px;
                // box-sizing: border-box;

                p {
                    margin: 0;
                    padding: 0;
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--custom-color);
                    margin-bottom: 20px;
                }

            }
        }
    }
    

    .login2 {
        // background-image: url('../assets/home/backgroundImage.png');
        background-color: var(--theme-color);
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;
        background-size: cover;
        // background-color: antiquewhite;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        .content {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;

        // .logo {
        //     z-index: 2;
        //     text-align: center;
        //     line-height: 160px;
        //     position: absolute;
        //     top: -160px;
        //     // left: calc(650px - 50% - 80px);
        //     // ;
        //     width: 160px;
        //     height: 160px;
        //     background: #FFFFFF;
        //     border-radius: 50%;
        //     box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3600);

        // }
    }

    .container {
        width: 96%;
    }
        .content {
            position: relative;

            .el-button {
                width: 100%;
                // font-size: 26px;
                border-radius: 4px;
                box-sizing: border-box;
                margin-top: 20px;
            }


            .logo {
                z-index: 2;
                text-align: center;
                line-height: 120px;
                position: absolute;
                top: -120px;
                // left: calc(550px - 50% - 80px);
                // ;
                width: 120px;
                height: 120px;
                background: #FFFFFF;
                border-radius: 50%;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3600);

            }

            .container {
                // width: 550px;
                // height: 614px;
                // background-color: rgba(255, 255, 255, 0.5);
                // border-radius: 12px;
                // // opacity: 0.5;
                // filter: blur(0px);
                // padding: 50px 55px;
                // padding-top: 120px;
                // box-sizing: border-box;

                p {
                    margin: 0;
                    padding: 0;
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--custom-color);
                    margin-bottom: 20px;
                }

            }
        }
    }

}
</style>